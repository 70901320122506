<template>
    <v-container>
        <v-card>
            <v-card-title>
            </v-card-title>
            <v-card-subtitle>
                <v-row>
                    <v-col cols="12" sm="6" md="4">
                        <v-text-field v-model="form.name" label="虚拟网名称" hide-details dense></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                        <v-select :items="items" v-model="form.auth" label="账号类型" dense></v-select>

                    </v-col>
                </v-row>
            </v-card-subtitle>
            <v-card-text>
                <div class="code-mirror-div">
                    <code-mirror-editor ref="cmEditor" cmTheme="default" cmMode="application/json" autoFormatJson=true jsonIndentation="2"></code-mirror-editor>
                </div>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="warning" dark small @click="$router.go(-1)">返回</v-btn>
                <v-btn color="success"  dark small @click="getValue">确认修改</v-btn>
            </v-card-actions>
        </v-card>
    </v-container>
</template>

<script>
    import { post, tips } from '@/facade';
    // 使用时需要根据CodeMirrorEditor.vue的实际存放路径，调整from后面的组件路径，以便正确引用
    import CodeMirrorEditor from "@/views/CodeMirror";
    export default {
        components: {
            CodeMirrorEditor
        },
        data() {
            return {
                form: { id: "", name: "", enabled: null },
                items: [
                    { text: '账号', value: 'uname' },
                    { text: '手机', value: 'phone' },
                    { text: '邮箱', value: 'email' },
                ],
                id: "",
                netInfo:{}
            };

        },
        async created() {
              const info=localStorage.getItem('netInfo')
             this.netInfo=JSON.parse(info);
             await this.getNetDetail();
        },

        methods: {
            //获取虚拟网信息
            async getNetDetail() {
                const data = await post('/vnet/network/get', { nid: this.netInfo.id })
                if (data.code == 'ok') {
                    this.form.id = data.network.id;
                    this.form.name = data.network.name;
                    this.form.enabled = data.network.enabled == 1 ? true : false;
                    this.$refs.cmEditor.setValue(data.network.setting);
                } else {
                    tips('error', data.message)
                }
            },
            //获取内容
            async getValue() {
                const value = await confirm('即将提交修改，是否继续')
                if (value) {
                    const content = this.$refs.cmEditor.getValue();
                    const form = {
                        nid: this.form.id,
                        name: this.form.name,
                        enabled: this.form.enabled,
                        setting: content
                    }
                    const data = await post('/vnet/network/update', form)
                    if (data.code == 'ok') {
                        tips('success', '修改成功')
                        this.getNetDetail()
                    } else {
                        tips('error', data.message)
                    }
                }
            },
            //添加虚拟网
            async addNet() {
                const value = await confirm('即将添加虚拟网，是否继续')
                if (value) {
                    const content = this.$refs.cmEditor.getValue();
                    if (this.form.name && content) {
                        const form = {
                            name: this.form.name,
                            setting: content,
                            auth: this.form.auth
                        }
                        const data = await post('/vnet/network/create', form)
                        if (data.code == 'ok') {
                            tips('success', '添加成功')

                            this.$router.replace('/home');
                        } else {
                            tips('error', data.message)
                        }
                    } else {
                        tips('error', "名称和配置不能为空")
                    }
                }
            }
        }
    };
</script>



<style>
    .CodeMirror {
        padding: 2px;
        height: 500px;
        overflow-y: auto;
    }
</style>



<style lang="scss" scoped>
    .code-mirror-div {
        padding: 2px;
        height: 500px;

        .tool-bar {
            top: 20px;
            margin: 30px 2px 0px 20px;
        }
    }
</style>